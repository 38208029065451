<template>
  <div id="weblaverie" class="weblaverie">
    <div class="fadeInRight">
      <h1 class="title">LES LAVERIES</h1>

      <p v-if="t_messages.length">
        <ul>
            <li v-bind:class="o_message.classe" v-for="o_message in t_messages" :key="o_message">
                {{ o_message.message }}
            </li>
        </ul>
      </p>

      <p class="subtitle" v-if="b_listeLaveriesOk">Sélectionne la laverie</p>
      <ul class="list" v-if="b_listeLaveriesOk">
        <li class="item" v-for="laverie in laveries" :key="laverie.id">
            <router-link :to="{name: 'Laverie', params: {code: laverie.cle} }">
              <h2 class="nom">{{ laverie.nom }}</h2>
            </router-link>
          </li>
      </ul>
    </div>
    <FooterSecondary/>     
  </div>
</template>

<script>
import axios from 'axios';
import FooterSecondary from '@/components/FooterSecondary.vue'
import {ConfigVars} from "../config-app";

export default {
    name: 'Weblaverie',
    components: {
      FooterSecondary,
    },
    data: function() {
        return {
            laveries: [],
            t_messages: [],
            b_listeLaveriesOk : false
        }
    },
    mounted: function() {
        console.log('Appel API : ' + ConfigVars.URL_API + 'laveries', {params: {},headers: {'Authorization': 'Bearer ' + this.$store.state.user.token}});
        
        /*
        axios.get( ConfigVars.URL_API + 'laveries')
        .then((response) => {
          console.log('success',response);
            this.b_listeLaveriesOk = true;
            this.laveries = response.data.data.laveries;
        })
        .catch((response) => {
          console.log('error',response);

          var objMessage = {
            message: 'Erreur a la recuperation des laveries',
            classe:"subtitle"
          }
          this.t_messages.push(objMessage);

        });
        */
        axios({
                method: 'get',
                url: ConfigVars.URL_API  + 'laveries',
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.user.token
                }
            })
            .then((response) => {
                console.log('success laveries', response) 
                this.b_listeLaveriesOk = true;
                this.laveries = response.data.data.laveries;
            })
            .catch((response) => {
              console.log('error',response);
              var objMessage = {
                message: 'Erreur a la recuperation des laveries',
                classe:"subtitle"
              }
              this.t_messages.push(objMessage);
            });
    }
}
</script>

<style scoped lang="scss">

  @import '../sass/animation';

  .weblaverie {
    padding: 2.4rem 2rem;
    position: relative;

    color: #FFF;

    border-radius: 20px 20px 0 0;
    
    background-color: var(--primary);

    & a {
      text-decoration: none;
      color: #FFF;
    }

    .title {
      font-size: 1.5625rem;
      font-weight: 730;
      margin-bottom: 0.7rem;
      text-align: center;
    }

    .subtitle {
      padding-bottom: 32px;
      font-size: 1.25rem;
      text-align: center;
      border-bottom: solid 1px #ffffff2b;
    }

    & .list li {
      padding: 1rem 0;
      border-bottom: solid 1px #ffffff2b;

      .nom {
        margin: 0 auto;
        width: max-content;
        border-bottom: transparent solid 2px;
        transition-duration: 0.1s;
      }

      .nom:hover {
        border-bottom: #FFF solid 2px;
      }
    }
  }

  @media only screen and (min-width: 950px) {
    .weblaverie {
      border-radius: 0;
    }
  }
</style>