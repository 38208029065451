<template>
  <div id="select-qr-list" class="select-qr-list">
    <router-link to="/">
        <button class="btn-proxi btn-selection btn-scanqr">
          Scanner un code QR
        </button>
    </router-link>
    <button class="btn-proxi btn-selection btn-liste" @click="$emit('listeMachineSelected')">
         Liste des machines
    </button>
  </div>
</template>

<script>
export default {
    name: 'SelectQrListLaverie',
}
</script>

<style lang="scss" scoped>
@import '../sass/button.scss';

.select-qr-list {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .btn-scanqr {
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 900px) {
    .select-qr-list {
        flex-direction: row;
        justify-content: space-around;

        & .btn-scanqr {
            margin-bottom: 0;
        }
    }
}

</style>