<template>
    <div id="liste-machine" class="liste-machine">
        <div class="button-container">
            <button class="btn-proxi btn-typemachine btn-lavl" v-bind:class="{ 'is-selected' : lavlIsSelected }" @click="sechlIsSelected = false;lavlIsSelected = true;$emit('changeToBlue')">
                Lave-linge
            </button>
            <button class="btn-proxi btn-typemachine btn-sechl" v-bind:class="{ 'is-selected' : sechlIsSelected }" @click="lavlIsSelected = false;sechlIsSelected = true;$emit('changeToOrange')">
                Sèche-linge
            </button>
        </div>
        <div class="list lavl-list" v-if="lavlIsSelected == true">
            <div v-for="machineLav in machinesLav" :key="machineLav.attributs.id">
                <MachineCard :o_reservation="machineLav.reservationmachine" :paiements="paiements" :dureeerestante="machineLav.attributs.dureeerestante" :id="machineLav.attributs.sitcle+machineLav.attributs.id" :nomLav="machineLav.attributs.sitnom" :nom="machineLav.attributs.nom" :num="machineLav.attributs.numero" :etanom="machineLav.attributs.etanom" :eta_id="Number(machineLav.attributs.etat)"/>      
            </div>
        </div>
        <div class="list sechl-list" v-if="sechlIsSelected == true">
            <div v-for="machineSec in machinesSec" :key="machineSec.attributs.id">
                <MachineCard :o_reservation="machineSec.reservationmachine" :paiements="paiements" :dureeerestante="machineSec.attributs.dureeerestante" :id="machineSec.attributs.sitcle+machineSec.attributs.id" :nomLav="machineSec.attributs.sitnom" :nom="machineSec.attributs.nom" :num="machineSec.attributs.numero" :etanom="machineSec.attributs.etanom" :eta_id="Number(machineSec.attributs.etat)"/>      
            </div>
        </div>
    </div>
</template>

<script>
import MachineCard from '../components/MachineCard.vue';

export default {
    name: 'ListeMachine',
    components: {
        MachineCard,
    },
    props: {
        machinesLav: {},
        machinesSec: {},

        paiements: {
            type: Array
        },
        listMachineAfficherLaveLingeDefaut: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return{
            lavlIsSelected: true,
            sechlIsSelected: false,
        }
    },
    mounted: function() {

        console.log('ListeMachine : listMachineAfficherLaveLingeDefaut = ' + this.listMachineAfficherLaveLingeDefaut);

        // Gestion du bouton retour de la fiche machine pour revenir par defaut sur la liste des lavelinge ou sechelinge
        if (Boolean(this.listMachineAfficherLaveLingeDefaut) == true)
        {
            this.lavlIsSelected = true;
            this.sechlIsSelected = false;
        }
        else
        {
            this.lavlIsSelected = false;
            this.sechlIsSelected = true;
        }

    }
}
</script>

<style lang="scss" scoped>
@import '../sass/button.scss';

.liste-machine {
    margin-top: 50px;

    & .button-container {
        display: flex;
        justify-content: space-around;
    }

    & .list {
        margin-top: 50px;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2rem;
        grid-column-gap: 1rem;
    }
}

@media only screen and (min-width: 580px) {
    .liste-machine .button-container {
        justify-content: center;

        & .btn-proxi {
            margin-right: 8px;
            margin-left: 8px;
        }
    }
}

@media only screen and (min-width: 950px) {
    .liste-machine .list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>