<template>
    <div id="login" class="login fadeInRight">
        <div class="logo-section">
            <img src="@/assets/icon_popup.png" class="logo"/>
        </div>
        <div class="login-form">
                <div class="form-group">
                    <input type="text" class="form-control" id="username" placeholder="Identifiant" v-model="username">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Mot de passe" v-model="password">
                </div>
                <div class="form-group-check">
                    <input class="form-checkbox" type="checkbox" v-model="remember">Se rappeler de moi
                </div>
                <p class="reset-pass"><span>Mot de passe oublié</span></p>
                <button class="btn-proxi btn-secondary btn-orange" @click="login()">
                    <span v-if="$store.state.status == 'loading'">Connexion en cours...</span>
                    <span>Connexion</span>
                </button>
                
        </div>
        <p v-if="errors.length" class="error">
            <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </p>
    </div>
</template>

<script>

export default {
    name: 'Login',
    props: {
        errorLog: String,
        machineid: {
            type: String,
            default: '1'
        },
    },
    data() {
        return {
            errors: [],
            username: null,
            password: null,
            remember: false,
        }
    },
    mounted: function() {

        console.log("Login : Machine : " + this.machineid);
        console.log("Login : errorLog : " + this.errorLog);

        if(this.errorLog != null && this.errorLog != '') {
            this.errors.push(this.errorLog);
        }
        
    },
    updated: function() {

        //console.log("updated ..." );
        
       
    },
    computed: {
        getStatut () {
            return this.$store.state.status;
        },
    },  
    methods: {
        checkForm: function () {
            this.errors = [];
            if (this.username && this.password) {
                return true;
            }

            if (!this.username) {
                this.errors.push('Identifiant requis.');
            }
            if (!this.password) {
                this.errors.push('Mot de passe requis.');
            }
        },
        login: function() {
            this.errors = [];
            if(this.checkForm()) {
                var rb = 0;
                if(this.remember == true) {
                    rb = 1;
                }else {
                    rb = 0;
                }               

                this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password,
                    rememberme: rb,
                    machineid:this.machineid
                })
            } 
        }
    },
}
</script>

<style lang="scss" scopped>

@import '../sass/button.scss';
@import '../sass/animation.scss';

.logo {
    height: 100px;
}

.error {
    margin-top: 24px;
    padding: 2rem;
    color: #FFF;

    border-radius: 10px;

    background-color: #f74040;
}

.login {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.login-form {
    margin-top: 38px;
    min-width: 260px;
    width: 100%;
    padding: 2rem;

    text-align: center;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    background-color: var(--accent);


}

@media only screen and (min-width: 425px) {
  .login-form {
      width: 425px;
  }

  .login {
      padding-top: 3rem;
  }
}

input {
    border: none;
    background: none;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 1.375rem;
    outline: none;
    width: 100%;
}



    .form-control {
        margin: 1rem 0;
        color: #FFF;

        border-bottom: 2px solid #FFF;

        &::placeholder {
            color: #FFF;
        }
    }

    .form-group-check {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 38px 0 38px 0;
        color: #FFF;

        font-weight: 600;

        .form-checkbox {
            height: 30px;
            width: 30px;
            
            border-radius: 5px;
        }
    }

    .reset-pass {
        margin-bottom: 38px;
        & span {
            color: #FFF;
            border-bottom: solid 1px #FFF;
        }
    }

    

</style>