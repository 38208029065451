<template>
    <div id="profile" class="profile">
        <div v-if="$store.state.status == 'loggedIn'">
            <ul class="menu-profils">
                <li @click="selected = 1" v-bind:class="{ active: selected === 1 }">
                    <h3>Profils</h3>
                </li>
                <li @click="selected = 2" v-bind:class="{ active: selected === 2 }">
                    <h3>Laverie favorite</h3>
                </li>
                <li @click="selected = 3;" v-bind:class="{ active: selected === 3 }">
                    <h3>Transactions</h3>
                </li>
                <!--<li @click="selected = 4" v-bind:class="{ active: selected === 4 }">
                    <h3>Mon solde</h3>
                </li>-->
                <li @click="selected = 5;" v-bind:class="{ active: selected === 5 }">
                    <h3>Incidents</h3>
                </li>
                <li class="dct" @click="$store.dispatch('logout',this.$store.state.remember); $router.push('/');">
                    <h3>Se déconnecter</h3>
                </li>
            </ul>
            <div class="container-profile">
                <div class="userinfo fadeInUp" v-if="selected === 1" >
                    <p><strong>Nom : </strong>{{ $store.state.user.data.attributs.nom}}</p><br>
                    <p><strong>Login : </strong>{{ $store.state.user.data.attributs.login}}</p><br>
                    <p><strong>Mail : </strong>{{ $store.state.user.data.attributs.mail}}</p><br>
                    <p><strong>Numéro badge : </strong>{{ $store.state.user.data.attributs.badge}}</p><br>
                </div>

                <div class="login-form fadeInUp" v-if="selected === 2">
                    <h3>Laverie favorite</h3>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Code laverie affiché dans la laverie" v-model="code">
                    </div>
                    <button class="btn-proxi btn-secondary btn-orange" @click="setFavLav()">
                        <span>ENREGISTRER</span>
                    </button>

                    <p class="messagelav" v-if="t_messages_favlav.length">
                        <ul>
                            <li v-bind:class="o_message.classe" v-for="o_message in t_messages_favlav" :key="o_message">
                                {{ o_message.message }}
                            </li>
                        </ul>
                    </p>
                </div>
                <!--
                <div class="transactions fadeInUp" v-if="selected === 3">
                    <h2>Transactions</h2>
                    <div class="grid">
                        <div class="transaction" v-for="transaction in transactions" :key="transaction.numero">
                            <p>{{ transaction.date }}</p>
                            <p>{{ transaction.libelleproduit }}</p>
                            <p>{{ transaction.site }}</p>
                            <p>{{ transaction.badge }}</p>
                        </div>
                    </div>
                    
                </div>
                -->
                <div class="transactions fadeInUp" v-if="selected === 5">
                    <h2>Bientôt disponible !</h2>
                    

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars} from "../config-app";

export default {
    name: 'Profile',
    data() {
        return {
            transactions: {},
            selected: 1,
            code: '',
            t_messages_favlav: [],
        }
    },
    mounted() {
    
    },
    computed() {
  
            this.transactions = this.$store.state.user.data.transactions.items;
       
    },
    methods: {
        
        setFavLav() {
            this.t_messages_favlav = [];

            if ( this.code =='')
            {
                var objMessage = {
                    message: 'Code laverie obligatoire !',
                    classe:"subtitle"
                }
                this.t_messages_favlav.push(objMessage);
            }

            if ( this.t_messages_favlav.length == 0)
            {
                axios({
                    method: 'put',
                    url: ConfigVars.URL_API  + 'utilisateurs/' + this.$store.state.user.data.attributs.login + '/favori',
                    data: {
                        site: this.code, 
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.user.token
                    }
                })
                .then((response) => {
                    console.log('success setfavlav', response) 
                    this.$store.dispatch('getUser', 
                        this.$store.state.user.data.attributs.login,
                    )
                    console.log('success setfavlav fin', response) 

                    var objMessage = {
                        message: 'Laverie favorite modifiée : ' + response.data.data.sit_nom,
                        classe:"subtitle"
                    }
                    this.t_messages_favlav.push(objMessage);
                })
                .catch((response) => {
                    
                    var s_msg_erreur = '';
                    if (response.response) {
                        // Request made and server responded
                        s_msg_erreur = 'Erreur : ' + response.response.data.title;
                    } else if (response.request) {
                        // The request was made but no response was received
                        s_msg_erreur = 'Erreur a la modification !' ;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        //console.log('Error', response.message);
                        s_msg_erreur = 'Erreur a la modification !' ;
                    }

                    var objMessage = {
                        message: s_msg_erreur,
                        classe:"subtitle"
                    }
                    this.t_messages_favlav.push(objMessage);
                });
            }
    }
    },
}
</script>

<style lang="scss" scoped>
@import '../sass/button.scss';
@import '../sass/form.scss';
@import '../sass/animation.scss';

.profile {
    position: relative;

    & .login-form {
        margin: 0;
        width: auto;
        min-width: none;
        max-width: 500px;

        & .messagelav {
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4rem;
            margin-top: 24px;
            border-radius: 10px;
        }
    }

    & .container-profile {
        background-color: #FFF;
        padding: 1rem;
    }


    
    & .menu-profils {
        display: flex;
        flex-wrap: wrap;

        background-color: var(--primary);

         & .dct {
                justify-self: flex-end;
            }
        /*border-bottom-right-radius: 25px;*/
        /*border-top-right-radius: 25px;*/

        

        & li {
            text-align: center;
            padding: 1rem;

            cursor: pointer;

            transition: 0.2s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }
            &.dct {
                background-color: #f74040;

                &:hover {
                    background-color: var(--accent);
                }
            }

            &.active  {
                & h3 {
                    color: var(--accent);
                }
            }

        
        }
    }

    & h3 {
        color: #FFF;
    }

    & .transactions {
        max-width: 300px;

        & .transaction {
            width: 100%;
            padding: 1rem;
            margin: 1rem 0;

            background-color: #EEE;

            border-radius: 10px;
        }
    }   

    & .userinfo {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 800px) {
        .profile .transactions {
            max-width: 800px;
            & .grid {
                width: 100%;
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }   
        
        
    }
</style>