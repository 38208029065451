<template>
  <div id="retour" class="retour">
    <h1>Votre commande :</h1>
    <div class="container-retour">
      <div class="logo-section">
        <img src="@/assets/icon_popup.png" class="logo"/>
      </div>
      <div class="content-retour">
        <!--<div class="loader"></div>-->
        <div class="message-box">
          <p v-if="message">{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars} from "../config-app";
import {EtatsReglementCB} from "../config-app";

export default {
    name: 'RetourPaymentCB',
    props: {
      query: String,
    },
    data() {
      return {
        interval: 'no',
        message: 'Recuperation des données ...',
        etatid: '',
        currentTransaction: {},
        b_transactionSelected: false,
      }
    },
    created() {
      console.log("created")
        this.getTransactionStatus();
     },
     
    mounted() { 
          this.interval = setInterval(() => {
            this.getTransactionStatus();
          }, 4000);
      
    },
    unmounted() {
      this.$store.dispatch('deleteCurentTransaction');
      clearInterval(this.interval);
    },
    methods: {
      checkRetrun() {

        // POUR TEST
        /*
        this.currentTransaction = {};
        this.currentTransaction.recharge = {};
        this.currentTransaction.recharge.etatnom = 'Réglé';
        this.currentTransaction.recharge.montant = '20';
        this.currentTransaction.recharge.etatid = '100001000001075';
        this.currentTransaction.recharge.ticket = "Identifiant du commer?ant : 211000033690001<br>Date de la transaction : 03/09/2021 15:29:37<br>R?f?rence de transaction : 030921152811100001000100220<br>Adresse IP : 91.160.142.182<br>Num?ro d'autorisation : 157567<br>Num?ro de carte : 5137##########42<br>Montant : 20.00 ?<br>";
        */

        if ( this.b_transactionSelected && this.currentTransaction && this.currentTransaction != null )
        {
          this.etatid = this.currentTransaction.etat;
          
          switch(Number(this.etatid)){
            case EtatsReglementCB.ETAT_REGLEMENTCB_REGLE :
              this.message = 'Transaction réussie : lancement de la machine ...';

              this.sleep(5000).then(() => {
                // Do something after the sleep!
                //this.message = 'Transaction réussie : lancement de la machine';

                console.log('Redirection sur le demarrage machine')
                console.log('Id machine brut : ' + this.currentTransaction.ins_idbrut)
                console.log('Ordre : ' + this.currentTransaction.deo_id)
                console.log('Montant : ' + this.currentTransaction.montant)

                this.$router.push({ name: 'Machine', params: { 
                  id: this.currentTransaction.ins_idbrut,
                  prop_order: this.currentTransaction.deo_id,
                  prop_etatComponent: 2, 
                  prop_montant: this.currentTransaction.montant,
                  prop_ref_nom:this.currentTransaction.ref_nom,
                }
                })              
                
              });

              break;

            case EtatsReglementCB.ETAT_REGLEMENTCB_ANNULATION :
              this.message = 'Transaction annulée';
              if ( this.currentTransaction.libellerefus != '')
                this.message += ' : ' + this.currentTransaction.libellerefus ;
              break;

            case EtatsReglementCB.ETAT_REGLEMENTCB_REFUSE :
              this.message = 'Transaction refusée';
              if ( this.currentTransaction.libellerefus != '')
                this.message += ' : ' + this.currentTransaction.libellerefus ;
              break;

            case EtatsReglementCB.ETAT_REGLEMENTCB_ATTENTEVALIDATIONBANQUE :
              this.message = 'Attente de validation de la banque';
              break;

            default:
              this.message = '';
              break;
          }
        }
        else
        {
          this.montant = '0';
          this.etatid = "1";
          this.t_ticketTransaction = [];
          this.message = 'Erreur a la recuperation de la transaction';
        }

      },
      sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      },
      getTransactionStatus() {
        axios({
                method: 'get',
                url: ConfigVars.URL_API + 'utilisateurs/cb_internet/' + this.query,
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.user.token
                }
            })
            .then((response) => {
                console.log('getTransactionStatus success', response) 
                this.currentTransaction = response.data.data;
                this.b_transactionSelected = true;
                this.checkRetrun();
            })
            .catch((response) => {
                this.b_transactionSelected = false;
                var s_msg_erreur = '';
                if (response.response && response.response.data && response.response.data.title != '')
                  s_msg_erreur = response.response.data.title;
                else
                  s_msg_erreur = response; 
                console.log('getTransactionStatus error : ',s_msg_erreur);
                this.checkRetrun();
            });
      },

    }
   
}
</script>

<style lang="scss" scopped>
//@import '../sass/loader';

.retour {
  padding: 1.2rem;
   display: flex;
  align-items: center;
  flex-direction: column;
  
  color: #FFF;

  background-color: var(--primary);
}

.container-retour {
  max-width: 800px;
  min-height: 200px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 4rem 3rem 4rem;
  margin-top: 40px;

  border-radius: 20px;
}

.content-retour {
  color: var(--primary);
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.loader {
  height: 32px;
  width: 32px;
  margin-right: 24px;

  border-width: 4px;
}

.message {
  font-size: 18px;
  font-weight: bold;
}


</style>