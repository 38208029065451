<template>
  <div id="fiche" class="fiche">
      <div class="fiche-content fadeInRight">
        <div class="fiche-header">
            <router-link class="btn-retour" v-bind:to="routerLinkRetour" @click="select = false">
                <img src="@/assets/icon_back.svg" alt="Retour">
            </router-link>
            <p class="subtitle">LAVERIE</p>
            <h2 class="title">{{ laverieInfo.nom }}</h2>
        </div>
        <ul class="menu">
            <li @click="selected = 1" v-bind:class="{ active: selected === 1 }">
                <img src="@/assets/icon_location_orange.svg" alt="" v-if="selected == 1">
                <img src="@/assets/icon_location.svg" alt="" v-else>
                <h3>CONTACT</h3>
            </li>
            <li @click="selected = 2" v-bind:class="{ active: selected === 2 }">
                <img src="@/assets/icon_horaires_orange.svg" alt="" v-if="selected == 2">
                <img src="@/assets/icon_horaires.svg" alt="" v-else>
                <h3>HORAIRES</h3>
            </li>
            <li @click="selected = 3;" v-bind:class="{ active: selected === 3 }">
                <img src="@/assets/icon_affluence_orange.svg" alt="" v-if="selected == 3">
                <img src="@/assets/icon_affluence.svg" alt="" v-else>
                <h3>AFFLUENCE</h3>
            </li>
            <li @click="selected = 4" v-bind:class="{ active: selected === 4 }">
                <img src="@/assets/icon_paiement_orange.svg" alt="" v-if="selected == 4">
                <img src="@/assets/icon_paiement.svg" alt="" v-else>
                <h3>PAIEMENT</h3>
            </li>
            <li @click="selected = 5;" v-bind:class="{ active: selected === 5 }">
                <img src="@/assets/Icon_tarifs_orange.svg" alt="" v-if="selected == 5">
                <img src="@/assets/icon_tarifs.svg" alt="" v-else>
                <h3>TARIFS</h3>
            </li>
        </ul>
        <div class="fiche-container">
            <div class="container-contact fadeInUp" v-if="selected == 1">
                <p>Tel : 07 86 03 98 42</p>
            </div>
            <div class="container-horaire" v-if="selected == 2">
                <div class="horaires fadeInUp"  v-for="horaire in horaires" :key="horaire.numerojour">
                    <p class="title">{{ horaire.nomjour }} : <span class="heure">{{ horaire.heureouverture }} - {{ horaire.heurefermeture }}</span></p>
                </div>
            </div>
             <div class="container-contact fadeInUp" v-if="selected == 3">
                <Affluence/>
            </div>
            <div class="container-contact fadeInUp" v-if="selected == 4">
                <div class="mode fadeInUp"  v-for="modepaiement in modepaiements" :key="modepaiement.id">
                    <div class="img-container">
                        <img v-bind:src="modepaiement.image" v-if="modepaiement.image">
                    </div>
                    
                    <p class="title">
                        {{ modepaiement.nom }} {{ modepaiement.texte }} 
                    </p>
                </div>
            </div>

             <div class="container-tarifs fadeInUp" v-if="selected == 5">

                <div class="list">

                <div v-for="o_tarifs_lavage in t_o_tarifs_lavage" :key="o_tarifs_lavage.capacite"> 
                    <MachineCardTarif 
                        :nom="o_tarifs_lavage.nom" 
                        :capacite="o_tarifs_lavage.capacite" 
                        :tarifs="o_tarifs_lavage.tarifs" 
                        :typetarif="o_tarifs_lavage.typetarif"
                    />   
                </div>

                <div v-for="o_tarifs_sechagehygrometrie in t_o_tarifs_sechagehygrometrie" :key="o_tarifs_sechagehygrometrie.capacite"> 
                    <MachineCardTarif 
                        :nom="o_tarifs_sechagehygrometrie.nom" 
                        :capacite="o_tarifs_sechagehygrometrie.capacite" 
                        :tarifs="o_tarifs_sechagehygrometrie.tarifs" 
                        :typetarif="o_tarifs_sechagehygrometrie.typetarif"
                    />   
                </div>

                <div v-for="o_tarifs_sechagetemps in t_o_tarifs_sechagetemps" :key="o_tarifs_sechagetemps.capacite"> 
                    <MachineCardTarif 
                        :nom="o_tarifs_sechagetemps.nom" 
                        :capacite="o_tarifs_sechagetemps.capacite" 
                        :tarifs="o_tarifs_sechagetemps.tarifs" 
                        :typetarif="o_tarifs_sechagetemps.typetarif"
                    />   
                </div>                   

                </div>

            </div>            
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Affluence from '@/components/Affluence.vue'
import MachineCardTarif from '@/components/MachineCardTarif.vue'
import {ConfigVars} from "../config-app";
import {ModesPaiements} from "../config-app";

export default {
    name: 'Fiche',
    props: {
        id: String,
    },
    components: {
        Affluence,
        MachineCardTarif
    },
    data() {
        return {
            laverieInfo: {},
            horaires: [],
            modepaiements: [],
            selected: 1,

            t_o_tarifs_lavage: [],
            t_o_tarifs_sechagehygrometrie: [],
            t_o_tarifs_sechagetemps: [],
        }
    },
    mounted: function() {
        console.log("Requete laverie ...")
        //console.log(this.laverieInfo)
        axios.get(ConfigVars.URL_API + 'laveries/' + this.id + '/tout')
        .then((response) => {
            console.log('success',response);
            this.laverieInfo = response.data.data.attributs;
            this.horaires = response.data.data.horaires.items;
 
            // --------------------------------------------------------------------------
            // MODES DE PAIEMENT
            // --------------------------------------------------------------------------
            for (var i = 0; i < response.data.data.paiements.items.length; i++) {
                //console.log('Mode : ' + response.data.data.paiements.items[i].id);

                var b_ajouterModepaiement = true;
                switch ( Number(response.data.data.paiements.items[i].id) )
                {
                    case ModesPaiements.MODEPAIEMENT_PIECES:
                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/monnaie.png';
                        break;
                    case ModesPaiements.MODEPAIEMENT_BILLETS:
                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/billets.png';
                        break;
                    case ModesPaiements.MODEPAIEMENT_NAYAX:
                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/CB.png';
                        break;
                    case ModesPaiements.MODEPAIEMENT_CARTEBANCAIRE:
                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/CB.png';
                        break;
                    case ModesPaiements.MODEPAIEMENT_IZLY:
                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/izly.png';
                        break;

                    case ModesPaiements.MODEPAIEMENT_COMPTE:
                        // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        var b_laverieEstPublique = false;
                        if ( b_laverieEstPublique) 
                            response.data.data.paiements.items[i].texte = ' : Pour bénéficier d\'une remise permanente de 10 %, vous pouvez ouvrir un compte Proxiwash et le créditer en ligne par carte bancaire ou sur la centrale de paiement. Si vous êtes un client professionnel, vous pouvez contactez le service commercial pour bénéficier de tarifs préférentiels';
                        else
                            response.data.data.paiements.items[i].texte = ' : Vous pouvez ouvrir un compte Proxiwash et le créditer en ligne par carte bancaire ou sur la centrale de paiement. Si vous êtes un client professionnel, vous pouvez contactez le service commercial pour bénéficier de tarifs préférentiels';

                        response.data.data.paiements.items[i].image = 'https://www.proxiwash.com/weblaverie/images/modespaiement/2021/mobile.png';
                        break;

                    default:
                        b_ajouterModepaiement = false;
                        break;
                }

                if ( b_ajouterModepaiement)
                    this.modepaiements.push(response.data.data.paiements.items[i]);

            }

            // --------------------------------------------------------------------------
            // TARIFS
            // --------------------------------------------------------------------------

            this.t_o_tarifs_lavage = [];
            for (let i in response.data.data.tarifs.items.lavage) 
            {
                //console.log('%d %s',i, response.data.data.tarifs.items.lavage[i]);
                var objTarifLavage = {
                    typetarif: 'lavage',
                    nom:"LAVE-LINGE",
                    capacite: i,
                    tarifs: response.data.data.tarifs.items.lavage[i]
                }
                this.t_o_tarifs_lavage.push(objTarifLavage);
            }

            this.t_o_tarifs_sechagehygrometrie = [];
            for (let i in response.data.data.tarifs.items.sechage_hygrometrie) 
            {
                //console.log('%d %s',i, response.data.data.tarifs.items.sechage_hygrometrie[i]);
                var objTarifSechagehygrometrie = {
                    typetarif: 'sechage_hygrometrie',
                    nom:"SECHE-LINGE",
                    capacite: i,
                    tarifs: response.data.data.tarifs.items.sechage_hygrometrie[i]
                }
                this.t_o_tarifs_sechagehygrometrie.push(objTarifSechagehygrometrie);
            }

            this.t_o_tarifs_sechagetemps = [];
            for (let i in response.data.data.tarifs.items.sechage_temps) 
            {
                //console.log('%d %s',i, response.data.data.tarifs.items.sechage_temps[i]);
                var objTarifSechageTemps = {
                    typetarif: 'sechage_temps',
                    nom:"SECHE-LINGE",
                    capacite: i,
                    tarifs: response.data.data.tarifs.items.sechage_temps[i]
                }
                this.t_o_tarifs_sechagetemps.push(objTarifSechageTemps);
            }
  

        })
        .catch((response) => {
          console.log('error',response);
        });
    },
    computed: {
        routerLinkRetour: function () {
            return '/laverie/' + this.id;
        }
    }
}
</script>

<style lang="scss" scopped>

@import '../sass/animation';

.fiche {
    padding: 2.4rem 0.5rem;
    display: flex;
    justify-content: center;

    color: #FFF;

    background-color: var(--primary);

    border-radius: 20px 20px 0 0;

    transition-duration: 0.2s;

    &.list-selected {
        position: static;
        height: fit-content;
    }

    & .fiche-container {
        margin-top: 50px;
        & .container-horaire {
            & .horaires {

                margin-bottom: 20px;
                
                & .title {
                    font-size: 1.2rem;

                    & span {
                        margin-left: 10px;
                        font-weight: 800;
                    }
                }
            }
        }
    }

    & .fiche-content {
        width: 100%;
        max-width: 90%;
        position: relative;

        & .fiche-header {
            text-align: center;

            & .subtitle {
                margin-bottom: 15px;
                letter-spacing: 2.1px;
                font-size: 0.875rem;
            }

            & .title {
                font-size: 1.5625rem;
                font-weight: 800;
            }

            & .btn-retour {
                position: absolute;
                top: 37px;
                left: 0.5rem;
            }
        }

        & .container-contact {

            & .mode {
                display: flex;
                align-items: center;

           

                & .img-container {
                    height: 100px;
                    width: 100px;

                    & img {
                        height: 4rem;
                        vertical-align: center;
                    }
                }

                & .title {
                    margin-left: 32px;
                }
            }
        }

        & .container-tarifs {
            & .list {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }
        }
        

        & .menu {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;
            align-items: baseline;
            overflow-x: scroll;

            & li {
                padding: 1rem;
                width: 100%;
                text-align: center;
                cursor: pointer;
                border-bottom: 3px solid rgba(255, 255, 255, 0.35);
                transition: 0.3s;

                img {
                    height: 24px;
                    margin-bottom: 8px;
                }

                &.active {
                    color: var(--accent);
                    border-bottom: 3px solid var(--accent);
                }
            }
        }
    }
}

@media only screen and (min-width: 950px) {
    .fiche {
        padding: 2.4rem 2rem;
        border-radius: 0;

        & .fiche-content .menu {
            overflow: hidden;
            
        }
    }
}

</style>