<template>
  <div id="laverie" class="laverie" v-bind:class="{ 'list-selected' : listMachineSelected }">
    <div class="lav-content fadeInRight">
        <div class="lav-header">
            <router-link class="btn-retour" to="/weblaverie" @click="select = false">
                <img src="@/assets/icon_back.svg" alt="Retour">
            </router-link>
            <p class="subtitle">LAVERIE</p>

            <router-link class="link" :to="{name: 'Fiche', params: {id: this.code} }">
                <h2 class="title" v-if="laverieInfo.nom">{{ laverieInfo.nom }}</h2>
            </router-link>

            <h2 class="title" v-if="laverieInfo.nom == ''">{{ nomLav }}</h2>
            <router-link v-if="laverieInfo.nom && laverieInfo.id > 1" class="btn-info" :to="{name: 'Fiche', params: {id: this.code} }">
                <img src="@/assets/icon_info.svg" alt="fiche laverie">
            </router-link>
        </div>
        <SelectQrListLaverie v-on:listeMachineSelected="listMachineSelected = true;colorBlue()" v-if="listMachineSelected == false"/>
        <ListeMachine :machinesLav="machinesLav" :machinesSec="machinesSec" :paiements="paiements" :listMachineAfficherLaveLingeDefaut="listMachineAfficherLaveLinge" v-on:changeToBlue="colorBlue()" v-on:changeToOrange="colorOrange()" v-if="listMachineSelected == true"/>
    </div>
</div>
</template>

<script>
//import MachineStatus from '../components/MachineStatus.vue';
import SelectQrListLaverie from '../components/SelectQrListLaverie.vue';
import ListeMachine from '../components/ListeMachine.vue';
import axios from 'axios';
import {ConfigVars} from "../config-app";
import {VarsIHM} from "../config-app";
import {Etats} from "../config-app";

export default {
    name: 'Laverie',
    components: {
        //MachineStatus,
        SelectQrListLaverie,
        ListeMachine
    },
    props: {
        code: String,
        nomLav: String,
        listMachineSelectedDefaut: {
            type: String,
            default: 'false'
        },
        listMachineAfficherLaveLingeDefaut: {
            type: String,
            default: 'true'
        },
    },
    data: function() {
        return {
            //machines: {},
            //secls: {},
            //lavls: {},
            
            // Objet infos laverie venant de l'API 
            laverieInfo: {},
            // Tableau des lave-linge venant de l'API 
            machinesLav: [],
            // Tableau des seche-linge venant de l'API 
            machinesSec: [],
            // Tableau des modes de paiement de la laverie venant de l'API 
            paiements: [],

            select: false,
            listMachineSelected: false,
            listMachineAfficherLaveLinge:true,  
            // Utilisé pour le timer requete laverie
            interval: null
        }
    },
    computed: {
        routerLinkRetour: function () {
            return 'laverie/' + this.code;
        }
    },
    beforeMount: function() {
        //this.listMachineAfficherLaveLinge = false;
    },
    mounted: function() {

        // Gestion du bouton retour de la fiche machine pour revenir par defaut sur la liste des machines
        if (this.listMachineSelectedDefaut == 'true')
            this.listMachineSelected = true;

        if ( VarsIHM.LISTEMACHINES_AFFICHER_BOUTONQRCODE == false)
        {
            this.listMachineSelected = true;
        }

        // Gestion du bouton retour de la fiche machine pour revenir par defaut sur la liste des lavelinge ou sechelinge
        if (this.listMachineAfficherLaveLingeDefaut == 'false')
        {
            this.listMachineAfficherLaveLinge = false;
            this.colorOrange();
        }
        else
            this.colorBlue();
        
        // Requete au serveur - fiche laverie : tout
        this.getLaverie()

        // En boucle toutes les 10s :Requete au serveur - fiche laverie : tout
        this.interval = setInterval(() => {
                this.getLaverie()
        }, 10000);

    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
    methods: {

        // Requete au serveur : Fiche laverie - tout
        getLaverie() {
            axios.get(ConfigVars.URL_API + 'laveries/' + this.code + '/tout',)
            .then((response) => {
                console.log('success laverie',response);
                // Stocker le tableau des lave linge

                if ( response.data.data.machines.items[2].items != null)
                {
                    for (var ll = 0; ll < response.data.data.machines.items[2].items.length; ll++) 
                    {
                        //console.log('test machine ' + response.data.data.machines.items[2].items[ll].attributs.id + ' - '+ response.data.data.machines.items[2].items[ll].attributs.nom + ' - ' + response.data.data.machines.items[2].items[ll].reservationmachine.id + ' - ' + response.data.data.machines.items[2].items[ll].reservationmachine.etat);
                        // Si la machine a une reservation en cours
                        if ( response.data.data.machines.items[2].items[ll].reservationmachine != null &&
                            Number(response.data.data.machines.items[2].items[ll].reservationmachine.id) > 1 &&
                            Number(response.data.data.machines.items[2].items[ll].reservationmachine.etat == Etats.ETAT_COURANT)
                            )
                        {
                            //console.log('machine ' + response.data.data.machines.items[2].items[ll].attributs.id + ' - '+ response.data.data.machines.items[2].items[ll].attributs.nom + ' reservee');
                            response.data.data.machines.items[2].items[ll].attributs.etanom = "RESERVE";
                        }
                    }
                }

                if ( response.data.data.machines.items[3].items != null)
                {
                    for (var sl = 0; sl < response.data.data.machines.items[3].items.length; sl++) 
                    {
                        //console.log('test machine ' + response.data.data.machines.items[3].items[sl].attributs.id + ' - '+ response.data.data.machines.items[3].items[sl].attributs.nom + ' - ' + response.data.data.machines.items[3].items[sl].reservationmachine.id + ' - ' + response.data.data.machines.items[3].items[sl].reservationmachine.etat);
                        // Si la machine a une reservation en cours
                        if ( response.data.data.machines.items[3].items[sl].reservationmachine != null &&
                            Number(response.data.data.machines.items[3].items[sl].reservationmachine.id) > 1 &&
                            Number(response.data.data.machines.items[3].items[sl].reservationmachine.etat == Etats.ETAT_COURANT)
                            )
                        {
                            //console.log('machine ' + response.data.data.machines.items[3].items[sl].attributs.id + ' - '+ response.data.data.machines.items[3].items[sl].attributs.nom + ' reservee');
                            response.data.data.machines.items[3].items[sl].attributs.etanom = "RESERVE";
                        }
                    }
                }

                this.machinesLav = response.data.data.machines.items[2].items;
                // Stocker le tableau des seche linge
                this.machinesSec = response.data.data.machines.items[3].items;
                // Stocker le tableau des modes de paiement de la laverie
                this.paiements = response.data.data.paiements.items;
                // Stocker le tableau des infos laverie
                this.laverieInfo = response.data.data.attributs;
            })
            .catch((response) => {
                console.log('error get laverie',response);
                this.laverieInfo  = {
                    nom: 'LAVERIE INCONNUE',
                    id: 1
                };
            });
        },
        colorOrange() {
            //console.log('colorOrange');
            var laverie = document.getElementById('laverie');
            laverie.style.backgroundColor = "#FF8C7D";
        },
        colorBlue() {
            //console.log('colorBlue');
            var laverie = document.getElementById('laverie');
            laverie.style.backgroundColor = "#25B4CC";
        }
    }
}
</script>

<style scoped lang="scss">

@import '../sass/animation';

.laverie {
    padding: 2.4rem 0.5rem;
    display: flex;
    justify-content: center;

    color: #FFF;

    background-color: var(--primary);

    border-radius: 20px 20px 0 0;

    transition-duration: 0.2s;

    &.list-selected {
        position: static;
        height: fit-content;
    }

    & .lav-content {
        width: 100%;
        max-width: 800px;
        position: relative;

        & .lav-header {
            text-align: center;

            & .subtitle {
                margin-bottom: 15px;
                letter-spacing: 2.1px;
                font-size: 0.875rem;
            }

            & .link {
                & .title {
                    font-size: 1.5625rem;
                    font-weight: 800;
                    color: #FFF;
                }
            }

            

            & .btn-retour {
                position: absolute;
                top: 37px;
                left: 0.5rem;
            }

            & .btn-info {
                position: absolute;
                top: 36px;
                right: 0.5rem;
            }
        }
    }
}

@media only screen and (min-width: 950px) {
    .laverie {
        padding: 2.4rem 2rem;
        border-radius: 0;
    }
}
</style>