<template>
    <div class="fadeInUp">
        <div id="machine--status" class="machine--status">
            <div class="machine__card--status">
                
                <div class="machine__card-content">
                    <div class="info-time">
                        <p class="etat-label">{{ nom }} {{ capacite }} kg</p>
                    </div>
                </div>

                <img v-bind:src="urlImg" v-if="urlImg" class="img">

                <ul>  
                    <li v-for="tarif in tarifs" :key="tarif.index">
                        {{ tarif[0] }} ...... {{ tarif[1] }}
                    </li>                        
                </ul>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MachineCardTarif',
    props: {
        nom: String,
        capacite: String,
        typetarif: String,
        tarifs: Array
    },
    data: function() {
        return {
            urlImg : null 
        }
    },
    mounted: function() {

        switch (this.typetarif)
        {
            case "lavage":
                switch ( this.capacite)
                {
                    case "6":
                    case "8":
                        this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PW6065-LL6kg.png";
                        break;
                    case "10":
                        this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PW6101-LL10kg.jpg";
                        break;
                    case "12":
                    case "14":
                    case "16":
                        this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PW6161-LL16kg.jpg";
                        break;
                }   
                break;

            case "sechage_hygrometrie":
                this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PT7135-SL6kg.jpg";
                break;

            case "sechage_temps":
                this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PT5351-SL14kg_big.jpg";
                break;

            default:
                this.urlImg = "https://www.proxiwash.com/weblaverie/images/machines/big/PT5351-SL14kg_big.jpg";
                break;
        }

        
    },
    
}   
</script>

<style scoped lang="scss">

@import '../sass/animation';

.etattime p {
    font-weight: bold;
}

.etat-label {
    font-weight: 800;
    margin-bottom: 20px;
}

.machine--status {
    margin-bottom: 32px;
    border-radius: 10px;
    background-color: #FFF;
    color: var(--primary);
    padding: 1rem;
    width: 300px;
    
    
    text-align: center;
    box-shadow: 0 5px 15px #24282926;

    //width: 100%;

    & ul {
        margin-top: 10px;
    }
}

.img {
    width: 150px;
}

.title {
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-family: 'Nunito';
    font-weight: bold;

    color: var(--primary);
}

.etat {
    color: #ccc;
    font-size: 0.8rem;
    font-family: 'Nunito';
}

.info-time {
    margin-top: 5px;
}

</style>