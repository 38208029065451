<template>
<div id="form-mercanet">    
    <form name="redirectForm" method="POST" :action="action" v-if="action != null && action != ''">
        <input type="hidden" name="Data" :value="Data">
        <input type="hidden" name="InterfaceVersion" :value="InterfaceVersion">
        <input type="hidden" name="Seal" :value="Seal"><noscript>
        <input type="submit" name="Go" value="Click to continue"/></noscript> 
    </form>
</div>
</template>

<script>
export default {
    name: 'FormMercanet',
    props: {
        action: String,
        Seal: String,
        Data: String,
        InterfaceVersion: String,
        autoredirection: {
            type: String,
            default: 'true'
        }
    },
    mounted() {
        // Si une action pour le form est definie : on valide le form pour aller sur le site mercanet
        if ( this.autoredirection == 'true')
        {
            if (  this.action != null && this.action != "")
            {
                console.log('FormMercanet : redirection OK - autoredirection : ' + this.autoredirection + ' - action : ' + this.action + ' - Seal : ' + this.Seal+ ' - Data : ' + this.Data+ ' - InterfaceVersion : ' + this.InterfaceVersion)
                document.redirectForm.submit();
            }
            else
            {
                // Pas d'action : retour sur la page d'accueil du site
                console.log("FormMercanet : action pas defini - retour a la page accueil");
                this.$router.push('/'); 
            }
        }
        else
            console.log('FormMercanet : pas de redirection auto');
    }
}
</script>

<style lang="scss" scopped>

</style>