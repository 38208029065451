<template>
    <div id="affluence" class="affluence">
        <div style="height:100px;width: 1000px;">
            <vue3-chart-js
                :id="affluChart.id"
                ref="chartRef"
                :type="affluChart.type"
                :data="affluChart.data"
                :options="affluChart.options"
            ></vue3-chart-js>
        </div>
    </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ref } from 'vue'

export default {
    name: 'Affluence',
    data() {
        return {

        }
    },
    components: {
        Vue3ChartJs,
    },
    setup () {
        const chartRef = ref(null)

        const data = {
            labels: ["07 h","08 h","09 h", "10 h","11 h","12 h", "13 h","14 h","15 h", "16 h","17 h","18 h","19 h","20 h","21 h", "22 h", "23 h"],
            datasets: [{
                backgroundColor: "rgba(255,255,255,0.8)",
                data: [100, 59, 80, 81, 56, 55, 40, 10, 65, 59, 80, 81, 56, 55, 40, 10, 80],
            }]
        };

        const affluChart = {
            id: 'affluChart',
            type: 'bar',
            data: data,
            options: {
                animation: true,
                showScale: false,
                plugins: {
                    backgroundColor: "rgba(255,255,255,0.8)",
                    legend: {
                        display: false,
                    },
                    labels: {
                        display: false,
                    }
                },
                
                scales: {
                    scaleShowVerticalLines: false,
                    x: {
                        grid: {
                            display: false
                        }
                        },
                        y: {
                            scaleShowVerticalLines: false,
                        grid: {
                            display: false
                        }
                        }
                }
            },
        }

        return {
            affluChart,
            chartRef
        }
    },

}
</script>

<style lang="scss" scopped>

.affluence {
    display: flex;
    justify-content: center;
}

</style>