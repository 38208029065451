<template>
    <div class="fadeInUp">
        <!--<router-link class="link" :to="routerLinkmachine">-->
            <div id="machine--status" class="machine--status" @click="clicMachine();">
                <div class="machine__card--status">
                    <p class="num">{{ num }}</p>
                    <p class="title">{{ nom }}</p>
                    <p class="etat">{{ etanom }}</p>
                    <div class="machine__card-content">
                        <div style="height:140px;width: 140px;display: flex;flex-direction:column;">
                            <vue3-chart-js
                                :id="doughnutChart.id"
                                ref="chartRef"
                                :type="doughnutChart.type"
                                :data="doughnutChart.data"
                                :options="doughnutChart.options"
                            ></vue3-chart-js>
                        </div>
                        <div class="info-time">
                            <div class="etattime" v-if="eta_id == ETAT_OCCUPE && dureeerestante != '0'">
                                <p>Restant</p>
                                <p>{{ dureeerestante }}</p>
                                <p>mm</p>
                            </div>
                            <!--
                            <p class="etat-label" v-else-if="eta_id == ETAT_OCCUPE && dureeerestante == '0'">OCCUPE</p>
                            <p class="etat-label" v-else-if="eta_id == ETAT_HS || eta_id == ETAT_HSMANUEL">HS</p>
                            <p class="etat-label" v-else-if="eta_id == ETAT_TERMINE">TERMINÉ</p>
                            <p class="etat-label" v-else-if="etanom == 'RESERVE' && machineEstReserveePourUserCourant == true">VOTRE RESERVATION {{ dateFinReservation }}</p>
                            <p class="etat-label" v-else-if="etanom == 'RESERVE'">RESERVÉ {{ dateFinReservation }}</p>
                            <p class="etat-label" v-else>LIBRE</p>
                            -->
                            <p class="etat-label">{{ this.getLabelMachine }}</p>

                        </div>
                    </div>
                </div>
            </div>
        <!--</router-link>-->
    </div>
</template>

<script>
import { ref } from 'vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import {Etats} from "../config-app";

export default {
    name: 'MachineStatus',
    components: {
        Vue3ChartJs,
    },
    props: {
        num: String,
        nom: String,
        etanom: String,
        eta_id: Number,
        dureeerestante: String,
        nomLav: String,
        id: String,
        o_reservation: Object,

        paiements: Array,
    },
    data: function() {
        return {
            interval: null,
            time: 0,
            datacollection: null,
            disable: false,

            ETAT_OCCUPE: Etats.ETAT_OCCUPE,
            /*
            ETAT_DISPONIBLE: Etats.ETAT_DISPONIBLE,
            ETAT_HS: Etats.ETAT_HS,
            ETAT_TERMINE: Etats.ETAT_TERMINE,
            ETAT_ERREUR: Etats.ETAT_ERREUR,
            ETAT_HSMANUEL: Etats.ETAT_HSMANUEL,
            ETAT_REGLE: Etats.ETAT_REGLE,
            ETAT_ARRETE: Etats.ETAT_ARRETE,
            */
            etc_id: 1
        }
    },
    computed: {

        getLabelMachine:  function () {
            console.log('getLabelMachine');
            if ( this.eta_id == Etats.ETAT_OCCUPE )
                return ( this.dureeerestante == '0' ) ? "OCCUPÉ" : "";
            else if ( this.eta_id == Etats.ETAT_HS || this.eta_id == Etats.ETAT_HSMANUEL)
                return "HS";
            else if ( this.eta_id == Etats.ETAT_TERMINE)
                return "TERMINÉ";
            else if ( this.eta_id == Etats.ETAT_REGLE)
                return "RÉGLÉ";
            else if ( this.etanom == 'RESERVE' && this.machineEstReserveePourUserCourant == true)
                return "VOTRE RÉSERVATION " + this.dateFinReservation;
            else if ( this.etanom == 'RESERVE')
                return "RÉSERVÉ " + this.dateFinReservation;
            else
                return "LIBRE";
        },

        machineEstReserveePourUserCourant: function () {
            return ( this.o_reservation != null &&
                Number(this.o_reservation.id) > 1 &&
                Number(this.o_reservation.etat) == Etats.ETAT_COURANT &&
                Number(this.o_reservation.entitecommerciale) == Number(this.etc_id)
                );
        },

        dateFinReservation: function () {
            //console.log('b_lienListeMachine ... ' )
            if ( this.o_reservation != null &&
                Number(this.o_reservation.id) > 1 &&
                Number(this.o_reservation.etat) == Etats.ETAT_COURANT
            )
            {
                //console.log('res ... ');
                return 'FIN: ' + this.o_reservation.datefin.substr(11, this.o_reservation.datefin.length - 14); //this.o_reservation.
            }
            return 'nok';
        },
        /*: function () {
            console.log('CLIC SUR LA MACHINE ' + this.id);
            if ( this.eta_id == this.ETAT_DISPONIBLE 
                && this.reservationExiste() == false
            ) 
            {
                console.log('MACHINE ' + this.id + ' OK');
                return {
                    name: 'Machine', 
                    params: {
                        id: this.id, 
                        nomLaverie: this.nomLav,
                        paiementsDataStr: JSON.stringify(this.paiements),
                    }
                } ;
            } 
            else 
            {
                console.log('MACHINE ' + this.id + ' NOK');
                return {};
            }
        }*/
    },
    mounted: function()  {

        this.etc_id = 1;
        if ( this.$store != null && this.$store.state.user != null && this.$store.state.user.data != null && this.$store.state.user.data.attributs != null )
            this.etc_id = this.$store.state.user.data.attributs.etc_id;

    },
    setup (props) {
        const chartRef = ref(null)
        var segment1 = 3600;
        var segment2 = 0;
        var colorSegment1 = '#31d0a7';
        var colorSegment2 = '#31d0a7';

        //if(props.etanom == 'OCCUPE'){
        if(props.eta_id == Etats.ETAT_OCCUPE)
        {
            colorSegment2 = '#FF8C7D'
        }

        if(props.eta_id == Etats.ETAT_TERMINE)
        {
            colorSegment2 = '#FFD700'
        }

        if(props.eta_id == Etats.ETAT_HS){
            colorSegment2 = '#DC143C'
        }

        if(props.eta_id == Etats.ETAT_HSMANUEL){
            colorSegment2 = '#DC143C'
        }

        const doughnutChart = {
            id: 'doughnut',
            type: 'doughnut',
            data: {
            datasets: [
                {
                    borderWidth: 0,
                    backgroundColor: [
                        colorSegment1,
                        colorSegment2
                    ],
                    data: [segment2,segment1]
                }
            ]
            },
            options: {
                responsive: true,
                cutout: 58,
                animation: false,
            }
        }

        const updateChartTerminer = () => {
            
            doughnutChart.data.datasets = [
                {
                    backgroundColor: [
                        '#FFD700',
                        '#FFD700'
                    ],
                    borderWidth: 0,
                    data: [segment2, segment1]
                }
            ]
        chartRef.value.update(250)
        }

        return {
            doughnutChart,
            updateChartTerminer,
            chartRef
        }
    },
    methods: {

        clicMachine() {
            console.log('CLIC SUR LA MACHINE ' + this.id + ' - etat : ' + this.eta_id);
            //console.log('User connecte : ' + this.etc_id + ' - user rsv : ' + (this.o_reservation != null ? this.o_reservation.entitecommerciale : 'pas de rsv'));

            if ( Number(this.eta_id) == Etats.ETAT_DISPONIBLE ) 
            {
                // S'il n'y a pas de reservation ou il y a une reservation pour soi-meme
                if (  (this.o_reservation == null || this.o_reservation == '')
                    ||
                    ( this.o_reservation != null &&
                            Number(this.o_reservation.id) > 1 &&
                            Number(this.o_reservation.etat) == Etats.ETAT_COURANT &&
                            Number(this.o_reservation.entitecommerciale) == Number(this.etc_id)
                            )
                )
                {
                     console.log('MACHINE ' + this.id + ' DISPO ET TEST DE RSV');
                    this.$router.push({ name: 'Machine', params: { 
                        id: this.id, 
                        nomLaverie: this.nomLav,
                        paiementsDataStr: JSON.stringify(this.paiements),
                        //o_reservation: JSON.stringify(this.o_reservation)
                    }})
                }
                else
                    console.log('MACHINE ' + this.id + ' test rsv NOK');

            }
            else
            {
                console.log('MACHINE ' + this.id + ' PAS DISPO');
            }

        }

    }
}   
</script>

<style scoped lang="scss">

@import '../sass/animation';

.etattime p {
    font-weight: bold;
}

.etat-label {
    font-weight: 800;
}

.num {
    height: 50px;
    width: 50px;
    font-size: 1.5rem;
    display: grid;
    font-weight: 800;
    place-items: center;
    border-radius: 50%;
    background-color: #FFF;
    position: absolute;
    top: -20px;
    box-shadow: 0 3px 6px #24282926;
    left: 50%;
    transform: translateX(-50%);
}

.machine--status {
    position: relative;
    border-radius: 10px;
    background-color: #FFF;
    color: var(--primary);
    padding: 2.5rem 1rem 1rem 1rem;
    text-align: center;
    box-shadow: 0 5px 15px #24282926;

    width: 100%;
}

.link::hover {
    transform: translateX(30px);
}

.machine--status.occuper {
    cursor: no-drop;
}

.machine__card-content {
    position: relative;
    height: 150px;
}

.machine__card-content div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}



.title {
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-family: 'Nunito';
    font-weight: bold;

    color: var(--primary);
}

.etat {
    color: #ccc;
    font-size: 0.8rem;
    font-family: 'Nunito';
}

.info-time {
    margin-top: 5px;
}

</style>